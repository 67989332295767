import {EmployeeProfileActivationType, EmployeeProfileRegistration} from "../../../../../models/registration";
import {authTypes} from "../../../../../di/types";
import {AddCustomerProfileUseCase, AddEmployeeProfileUseCase} from "../../../application";

const isLoading = ref(false);

const profileResponse = ref();

export function useAddProfile() {
  const { $visitorId, $container } = useNuxtApp();
  const addCustomerProfileUseCase: AddCustomerProfileUseCase = $container.get<AddCustomerProfileUseCase>(authTypes.AddCustomerProfileUseCase);
  const addEmployeeProfileUseCase: AddEmployeeProfileUseCase = $container.get<AddEmployeeProfileUseCase>(authTypes.AddEmployeeProfileUseCase);

  const {fetchSession, session} = useAuthSession();

  async function addCustomerProfile() {
    isLoading.value = true;
    try {
      await fetchSession();
      profileResponse.value = await addCustomerProfileUseCase.execute($visitorId);
    } finally {
      isLoading.value = false;
    }
  }

  async function addEmployeeProfile(formData: EmployeeProfileRegistration) {
    isLoading.value = true;
    try {
      await fetchSession();
      if (formData.activationType === EmployeeProfileActivationType.EMAIL) {
        profileResponse.value = await addEmployeeProfileUseCase.createByEmail(
          formData.corporateEmail as string, formData.problemDescription, formData.requests
        );
      } else if (formData.activationType === EmployeeProfileActivationType.INVITE_CODE) {
        profileResponse.value = await addEmployeeProfileUseCase.createByInviteCode(
          $visitorId, formData.inviteCode as string, formData.problemDescription, formData.requests
        );
      }
    } catch (e) { }
    finally {
      isLoading.value = false;
    }
  }

  async function confirmCorporateEmail(code: string) {
    isLoading.value = true;
    try {
      await fetchSession();
      profileResponse.value = await addEmployeeProfileUseCase.confirmEmail(
        profileResponse.value?.profileId ?? session.value.selectedProfile.id, code, $visitorId
      );
    }
    finally {
      isLoading.value = false;
    }
  }

  async function resendCorporateEmail() {
    isLoading.value = true;
    try {
      await fetchSession();
      await addEmployeeProfileUseCase.resendCode(profileResponse.value.profileId);
    }
    finally {
      isLoading.value = false;
    }
  }

  async function changeCorporateEmail(email: string) {
    isLoading.value = true;
    try {
      await fetchSession();
      await addEmployeeProfileUseCase.changeCorporateEmail(session.value.selectedProfile.id, email);
    }
    finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    profileResponse,
    addCustomerProfile,
    addEmployeeProfile,
    confirmCorporateEmail,
    resendCorporateEmail,
    changeCorporateEmail,
  }
}
